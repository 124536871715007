import React from "react"

export default {
  "Dania obiadowe": [
    { name: "Strogonow", nameDesc: "zapiekany ziemniakami", price: "22,00 zł" },
    {
      name: "Chrupiące polędwiczki",
      nameDesc: "z kurczaka z frytkami i surówką",
      price: "25,50 zł",
    },
    {
      name: "Gołąbki",
      nameDesc: "bez zawijania z puree z ziemniaków i buraczkami",
      price: "27,00 zł",
    },
    { name: "Penne", nameDesc: "ze szpinakiem", price: "28,50 zł" },
    {
      name: "Kotlet de'volaille",
      nameDesc: "z puree z ziemniaków i surówką",
      price: "29,00 zł",
    },
    {
      name: "Kotlet schabowy",
      nameDesc: "z kapustą zasmażaną i puree z ziemniaków",
      price: "29,50 zł",
    },
    {
      name: "Kebab",
      nameDesc: "na talerzu z frytkami i warzywami",
      price: "32,50 zł",
    },
    {
      name: "Kurczak w 5 smakach",
      nameDesc: "z ryżem i surówką",
      price: "32,50 zł",
    },
    {
      name: "Placki ziemniaczane",
      nameDesc: "z gulaszem cygańskim",
      price: "35,00 zł",
    },
    {
      name: "Karkówka grillowana",
      nameDesc: "i duszona z ćwiartkami ziemniaków i warzywami gotowanymi",
      price: "36,00 zł",
    },
    {
      name: "Dorsz z łódkami",
      nameDesc: "z ziemniaków, surówką i sosem koperkowym",
      price: "36,00 zł",
    },
    {
      name: "Zrazy z karkówki",
      nameDesc: "z ziemniakami i buraczkami",
      price: "37,50 zł",
    },
    {
      name: "Golonka",
      nameDesc: "z ćwiartkami ziemniaków, kapustą i sosem chrzanowym",
      price: "49,50 zł",
    },
    { name: "Frytki", price: "12,00 zł", margin: true },
    {
      name: "Rollo",
      nameDesc: "kebab",
      price: "32,50 zł",
    },
    {
      pre: "Kubełek chrupiących",
      name: "skrzydełek i nuggetsów",
      nameDesc: "15 / 30 szt.",
      price: "40,00 / 76,50 zł",
    },
  ],
  "Menu dla dzieci": [
    {
      name: "Nuggetsy",
      nameDesc: "z kurczaka z frytkami i sosem",
      price: "17,50 zł",
    },
    {
      name: "Naleśniki",
      nameDesc: "z twarogiem i sosem czekoladowym lub truskawkowym",
      price: "19,50 zł",
    },
    {
      name: "Kotlet drobiowy",
      nameDesc: "z puree z ziemniaków i warzywami gotowanymi",
      price: "21,00 zł",
    },
    {
      name: "Pizza",
      nameDesc: "junior 25 cm (sos, ser, szynka)",
      price: "29,50 zł",
    },
  ],
  Pizza: [
    {
      name: "Margherita",
      nameDescJSX: <>sos, ser</>,
      price: "27,50 / 43,00 zł",
    },
    {
      name: "Marga z pieczarkami",
      nameDescJSX: <>sos, pieczarki, ser</>,
      price: "29,50 / 48,50 zł",
    },
    {
      name: "Junior",
      nameDescJSX: <>sos, szynka, ser</>,
      price: "29,50 / 48,50 zł",
    },
    {
      name: "Capriciosa",
      nameDescJSX: <>sos, szynka, pieczarki, ser</>,
      price: "31,00 / 51,50 zł",
    },
    {
      name: "Hawajska",
      nameDescJSX: <>sos, szynka, ananas, ser</>,
      price: "31,00 / 51,50 zł",
    },
    {
      name: "Ognista",
      nameDescJSX: <>sos, salami, pieczarki, papryczki chilli, ser</>,
      price: "31,00 / 52,50 zł",
    },
    {
      name: "Carbonara",
      nameDescJSX: <>sos beszamel, bekon, cebula, pieczarki, ser</>,
      price: "35,00 / 52,50 zł",
    },
    {
      name: "USA chicken",
      nameDescJSX: <>sos, kurczak, cebula, kukurydza, ser</>,
      price: "35,00 / 53,50 zł",
    },
    {
      name: "Biała dama",
      nameDescJSX: <>sos beszamel, kurczak, brokuł, cebula, ser</>,
      price: "35,00 / 53,50 zł",
    },
    {
      name: "Wiejska",
      nameDescJSX: <>sos, kiełbasa, pieczarki, ogórek, cebula, ser</>,
      price: "35,00 / 53,50 zł",
    },
    {
      name: "AbraKEBABra",
      nameDescJSX: <>sos, kurczak kebab, pieczarki, cebula, papryka, ser</>,
      price: "35,00 / 53,50 zł",
    },
    {
      name: "3 sery",
      nameDescJSX: <>sos, ser gouda, ser pleśniowy, ser parmezan</>,
      price: "39,00 / 64,50 zł",
    },
  ],
  "Gorące półmiski": [
    {
      pre: "Półmisek",
      name: "RODZINNY",
      price: "99,00 zł",
    },
    {
      name: "Strucla z kurczakiem i warzywami",
      price: "99,00 zł",
    },
    {
      pre: "Półmisek",
      name: "OBIADOWY",
      price: "199,00 zł",
    },
    {
      pre: "Półmisek",
      name: "MIĘS I",
      price: "239,00 zł",
    },
    {
      pre: "Półmisek",
      name: "MIĘS II",
      price: "279,00 zł",
    },
    {
      pre: "Półmisek",
      name: "MIĘS III",
      price: "369,00 zł",
    },
    {
      pre: "Półmisek",
      name: "KEBAB",
      price: "239,00 zł",
    },
    {
      pre: "Półmisek",
      name: "CHIŃSKI",
      price: "259,00 zł",
    },
    {
      pre: "Półmisek",
      name: "MŁODZIEŻOWY",
      price: "259,00 zł",
    },
    {
      pre: "Półmisek",
      name: "DRWALA",
      price: "259,00 zł",
    },
  ],
  Zupy: [
    { name: "Rosół", nameDesc: "z makaronem", price: "15,00 zł" },
    { name: "Flaki", price: "17,00 zł" },
    { name: "Meksykańska", nameDesc: "zupa pikantna", price: "17,50 zł" },
    { name: "Żurek", nameDesc: "staropolski", price: "18,00 zł" },
    { name: "Zupa", nameDesc: "sezonowa", price: "15,00 zł" },
  ],
  Sałatki: [
    { name: "Sałatka", nameDesc: "słoneczna z kurczakiem", price: "24,50 zł" },
    { name: "Sałatka", nameDesc: "grecka", price: "24,50 zł" },
    { name: "Sałatka", nameDesc: "wiejska", price: "28,50 zł" },
  ],
  Napoje: [
    { name: "Kawa parzona", price: "8,00 zł" },
    { name: "Herbata", price: "7,00 zł" },
    { name: "Kawa czarna", price: "9,50 zł" },
    { name: "Kawa biała", price: "10,50 zł" },
    { name: "Cappuccino", price: "13,00 zł" },
    { name: "Latte macchiato", price: "14,00 zł" },
    { name: "Espresso", price: "10,50 zł" },
    { name: "Pepsi, mirinda, 7up, ice tea 0,5l", price: "8,50 zł" },
    { name: "Woda 0,3l / 0.5l", price: "6,00 zł" },
    {
      name: "Sok jabłkowy, pomarańczowy, czarna porzeczka 0,3l",
      price: "7,50 zł",
    },
  ],
  Desery: [
    { name: "Jabłecznik", nameDesc: "z sosem waniliowym", price: "10,00 zł" },
    { name: "bon", nameDesc: "Deser", price: "14,50 zł" },
  ],
}
